import './elements/bypass-reason-selector'
import {GetCharIndexFromBytePosition} from '../text'

import {emitToCodeMirror} from '@github-ui/code-mirror/emit-to-codemirror'
import {SecretDetectedLintEventDetail} from '@github-ui/code-mirror/types/custom-events'

export class CodeEditorExtensions {
  private static parseDiscoveredSecrets(fileContents: string, discoveredSecrets: string) {
    const secrets = JSON.parse(discoveredSecrets)

    if (secrets.length === 0 || secrets[0].locations?.length === 0) {
      return null
    }

    // for now only annotate the first secret. We will support multiple secrets later
    const location = secrets[0].locations[0]
    const lines = fileContents.split(/\r?\n/)

    // rails and react use different keys for the same thing - normalize them
    const normalizedStartLine = location.start_line || location.startLine
    const normalizedEndLine = location.end_line || location.endLine
    const normalizedStartBytePosition = location.start_line_byte_position || location.startLineBytePosition || 0
    const normalizedEndBytePosition = location.end_line_byte_position || location.endLineBytePosition

    const startLine = normalizedStartLine - 1 // lines in a location are 1-indexed
    const endLine = normalizedEndLine - 1

    if (startLine > endLine || startLine < 0 || endLine > lines.length) {
      return
    }

    // lines may have changed during client side editing, don't let it blow the page up
    if (!lines[startLine] || !lines[endLine]) {
      return
    }

    return {
      startLine,
      endLine,
      startLineCharacter: GetCharIndexFromBytePosition(lines[startLine]!, normalizedStartBytePosition),
      endLineCharacter: GetCharIndexFromBytePosition(lines[endLine]!, normalizedEndBytePosition),
    }
  }

  public static annotateDiscoveredSecrets(codeEditor: CodeMirror.Editor, discoveredSecrets: string) {
    if (!codeEditor) {
      return
    }

    const secrets = CodeEditorExtensions.parseDiscoveredSecrets(codeEditor.getValue(), discoveredSecrets)

    if (!secrets) {
      return
    }

    const {startLine, endLine, startLineCharacter, endLineCharacter} = secrets

    codeEditor.markText(
      {line: startLine, ch: startLineCharacter},
      {line: endLine, ch: endLineCharacter},
      {className: 'CodeMirror-lint-mark-error'},
    )
  }

  public static emitAnnotateDiscoveredSecrets(fileContents: string, discoveredSecrets: string, target: Element) {
    const secrets = CodeEditorExtensions.parseDiscoveredSecrets(fileContents, discoveredSecrets)

    if (!secrets) {
      return
    }

    const {startLine, endLine, startLineCharacter, endLineCharacter} = secrets

    const detail: SecretDetectedLintEventDetail = {
      from: {
        lineNumber: startLine,
        character: startLineCharacter,
      },
      to: {lineNumber: endLine, character: endLineCharacter},
      severity: 'error',
    }

    emitToCodeMirror('secret-detected:lint', target, detail)
  }
}
