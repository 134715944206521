import type {Doc, Editor, LineHandle} from 'codemirror'
import CodeMirror from '../codemirror'
import {fetchPoll} from '../fetch'
import {getAsyncCodeEditor} from '../code-editor'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {validate} from '../behaviors/html-validation'
import verifySsoSession from '../sso'

interface FileMergeData {
  document: Doc
  headDocument: Doc
  baseDocument: Doc
  conflicts: ConflictInfo[]
}

interface ConflictInfo {
  start?: LineHandle
  middle?: LineHandle
  end?: LineHandle
}

function persistenceKeyFor(filename: string): HTMLElement {
  const persistenceForm = document.querySelector<HTMLFormElement>('.js-resolve-conflicts-form')!
  return persistenceForm.elements.namedItem(filename) as HTMLElement
}

const fileDataCache: WeakMap<HTMLElement, FileMergeData> = new WeakMap()

function getFileData(filename: string): FileMergeData | undefined {
  const persistenceField = persistenceKeyFor(filename)
  return fileDataCache.get(persistenceField)
}

function setFileData(filename: string, data: FileMergeData): void {
  fileDataCache.set(persistenceKeyFor(filename), data)
}

async function setupResolverFor(elem: HTMLAnchorElement) {
  const editorElement = document.querySelector('.js-code-editor')

  if (!(editorElement instanceof HTMLElement)) {
    return
  }

  const editor = await getAsyncCodeEditor(editorElement)

  function onChange() {
    const filename = elem.getAttribute('data-filename') || ''
    const fieldName = `files[${filename}]`

    const data = getFileData(fieldName)

    if (data == null) {
      throw new Error(`Expected file data to be loaded and was not`)
    }

    findAndMarkConflicts(data)
  }

  editor.off('change', onChange)
  editor.on('change', onChange)

  const resolver = editorElement.closest('.js-conflict-resolver')

  if (resolver == null) {
    return
  }

  resolver.classList.add('loading')

  editor.loading(async () => {
    const filename = elem.getAttribute('data-filename')
    if (!filename) {
      return
    }

    const fieldName = `files[${filename}]`
    const resolveFileForm = document.querySelector<HTMLFormElement>('.js-resolve-file-form')!
    ;(resolveFileForm.elements.namedItem('filename') as HTMLInputElement).value = fieldName
    resolveFileForm.querySelector<HTMLElement>('.js-filename')!.textContent = decodeURIComponent(filename)
    resolveFileForm.classList.toggle('is-resolved', elem.classList.contains('resolved'))

    for (const el of elem.parentNode!.children) {
      el.classList.toggle('selected', el === elem)
    }

    let contents = getFileData(fieldName)

    if (!contents) {
      const response = await fetch(elem.href, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
      })
      if (!response.ok) {
        const responseError = new Error()
        const statusText = response.statusText ? ` ${response.statusText}` : ''
        responseError.message = `HTTP ${response.status}${statusText}`
        throw responseError
      }
      const rawData = await response.json()

      contents = {
        document: CodeMirror.Doc(rawData.conflicted_file.data, rawData.conflicted_file.codemirror_mime_type),
        headDocument: CodeMirror.Doc(rawData.head.data, rawData.head.codemirror_mime_type),
        baseDocument: CodeMirror.Doc(rawData.base.data, rawData.base.codemirror_mime_type),
        conflicts: [],
      }

      findAndMarkConflicts(contents)

      setFileData(fieldName, contents)
    }

    resolver.classList.remove('loading')

    editor.setDocument(contents.document)

    document.querySelector<HTMLElement>('.js-code-editor .js-conflict-count')!.textContent = new Intl.NumberFormat(
      'en-US',
    ).format(contents.conflicts.length)
    const label = document.querySelector<HTMLElement>('.js-code-editor .js-conflict-label')!
    label.textContent = label.getAttribute(
      contents.conflicts.length === 1 ? 'data-singular-string' : 'data-plural-string',
    )

    goToConflict(true)
  })
}

function findAndMarkConflicts(contents: {document: Doc; conflicts: unknown[]}) {
  let currentConflict: ConflictInfo = {}
  contents.conflicts = []

  contents.document.eachLine(lineHandle => {
    if (!lineHandle) {
      return
    }

    const editor = contents.document as Editor

    editor.setGutterMarker(lineHandle, 'merge-gutter', null)

    if (!currentConflict.start && /^<<<<<<</.test(lineHandle.text)) {
      currentConflict.start = lineHandle
    } else if (currentConflict.start && /^=======/.test(lineHandle.text)) {
      currentConflict.middle = lineHandle
    } else if (currentConflict.start && /^>>>>>>>/.test(lineHandle.text)) {
      currentConflict.end = lineHandle
    }

    if (currentConflict.start) {
      let selector = '.js-line'

      if (currentConflict.start === lineHandle) {
        selector = '.js-start'
      } else if (currentConflict.middle === lineHandle) {
        selector = '.js-middle'
      } else if (currentConflict.end === lineHandle) {
        selector = '.js-end'
      }

      const element = document.querySelector<HTMLElement>(`.js-conflict-gutters ${selector}`)!.cloneNode(true)

      editor.setGutterMarker(lineHandle, 'merge-gutter', element as HTMLElement)
    }

    if (currentConflict.end) {
      contents.conflicts.push(currentConflict)
      currentConflict = {}
    }
  })
}

function goToConflict(forward: boolean) {
  const resolveFileForm = document.querySelector<HTMLFormElement>('.js-resolve-file-form')!
  const fileDataKey = (resolveFileForm.elements.namedItem('filename') as HTMLInputElement).value

  const fileData = getFileData(fileDataKey)

  if (fileData == null) {
    throw new Error(`Expected file data to be loaded and was not`)
  }

  const currentLine = fileData.document.getCursor().line

  const conflicts = fileData.conflicts

  let newLineNumber = null

  for (let i = forward ? 0 : conflicts.length - 1; forward ? i < conflicts.length : i >= 0; forward ? i++ : i--) {
    const conflictMiddle = conflicts[i]!.middle

    if (conflictMiddle == null) {
      continue
    }

    const lineNumber = fileData.document.getLineNumber(conflictMiddle)!

    if ((forward && lineNumber > currentLine) || (!forward && lineNumber < currentLine)) {
      newLineNumber = lineNumber
      break
    }
  }

  if (newLineNumber != null) {
    fileData.document.setCursor(newLineNumber, 0)
  }

  fileData.document.getEditor()!.focus()
}

on('click', 'a.js-conflicted-file', function (event) {
  const currentTarget = event.currentTarget as HTMLAnchorElement

  event.preventDefault()
  setupResolverFor(currentTarget)
})

on('click', '.js-prev-conflict', function (e) {
  goToConflict(false)
  e.preventDefault()
})

on('click', '.js-next-conflict', function (e) {
  goToConflict(true)
  e.preventDefault()
})

on('submit', 'form.js-resolve-file-form', function (event) {
  const form = event.currentTarget as HTMLFormElement

  event.preventDefault()

  if (form.querySelector<HTMLElement>('button.js-mark-resolved')!.classList.contains('disabled')) {
    return
  }

  const currentConflict = document.querySelector('.js-conflicted-file.selected')
  if (currentConflict) {
    currentConflict.classList.add('resolved')
  }

  const resolvedCount = document.querySelectorAll('.js-conflicted-file.resolved').length
  const conflictCount = document.querySelectorAll('.js-conflicted-file').length

  if (resolvedCount === conflictCount) {
    const completeElement = document.querySelector('.js-resolve-conflicts-complete')
    if (completeElement) {
      /* eslint-disable-next-line github/no-d-none */
      completeElement.classList.remove('d-none')
    }

    const resolveForm = document.querySelector('.js-resolve-file-form')
    if (resolveForm) {
      resolveForm.classList.add('is-resolved')
    }
  }

  // If there's another conflict after this one, automatically go to it
  let nextConflict = currentConflict

  if (nextConflict != null) {
    do {
      nextConflict = nextConflict.nextElementSibling
    } while (nextConflict && !nextConflict.classList.contains('js-conflicted-file'))
  }

  if (nextConflict instanceof HTMLAnchorElement) {
    return setupResolverFor(nextConflict)
  }
})

on('submit', 'form.js-resolve-conflicts-form', async function (event) {
  const form = event.currentTarget as HTMLFormElement

  event.preventDefault()

  await verifySsoSession()

  //build up the form data to submit
  const formData = new FormData(form)
  const files = form.querySelectorAll<HTMLInputElement>('input.js-file')
  for (const file of files) {
    const data = getFileData(file.name)

    if (data == null) {
      throw new Error(`Expected file data to be loaded and was not`)
    }

    formData.append(file.name, data.document.getValue())
  }

  //submit
  const response = await fetch(form.action, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (!response.ok) {
    const responseError = new Error()
    const statusText = response.statusText ? ` ${response.statusText}` : ''
    responseError.message = `HTTP ${response.status}${statusText}`
    throw responseError
  }
  const resp = await response.json()

  try {
    if (resp.error) {
      // in this case, we knew before we queued the job that another push happened
      throw new Error(resp.error)
    }

    await fetchPoll(resp.job.url)

    const editorElement = document.querySelector<HTMLElement>('.js-code-editor')!

    const editor = await getAsyncCodeEditor(editorElement)

    editor.clearConfirmUnloadMessage()

    window.location.pathname += '/..'
  } catch (e) {
    // show the error flash if the job failed
    const completeElement = document.querySelector('.js-resolve-conflicts-complete')

    if (completeElement) {
      /* eslint-disable-next-line github/no-d-none */
      completeElement.classList.toggle('d-none')
    }

    const failedElement = document.querySelector('.js-resolve-conflicts-failed')
    if (failedElement) {
      /* eslint-disable-next-line github/no-d-none */
      failedElement.classList.toggle('d-none')
    }
  }
})

on('change', '.js-conflict-resolver .js-code-editor', async function (event) {
  const currentTarget = event.currentTarget as HTMLElement

  const editor = await getAsyncCodeEditor(currentTarget)

  const resolveFileForm = document.querySelector<HTMLFormElement>('.js-resolve-file-form')!
  const fileDataKey = (resolveFileForm.elements.namedItem('filename') as HTMLInputElement).value

  const data = getFileData(fileDataKey)

  if (data == null) {
    throw new Error(`Expected file data to be loaded and was not`)
  }

  const hasConflicts = data.conflicts.length !== 0 || /^[<>]{7}/m.test(editor.code())

  const button = currentTarget.querySelector<HTMLButtonElement>('button.js-mark-resolved')!
  button.classList.toggle('disabled', hasConflicts)
  button.classList.toggle('tooltipped', hasConflicts)
  const label = hasConflicts ? button.getAttribute('data-disabled-label') : ''
  if (label) button.setAttribute('aria-label', label)
})

observe('.js-conflict-list', function (el) {
  const firstConflict = el.querySelector('.js-conflicted-file')
  if (firstConflict instanceof HTMLAnchorElement) {
    setupResolverFor(firstConflict)
  }

  // Ensure page goes full width when PJAXing in
  const timeline = document.querySelector('.new-discussion-timeline')
  if (timeline) {
    timeline.classList.remove('px-3')
    timeline.classList.add('p-0')
  }
})

on('change', '.js-conflict-resolution-choice-option', function (event) {
  const target = event.target as HTMLInputElement

  const form = target.closest<HTMLFormElement>('.js-resolve-conflicts-form')!
  const button = form.querySelector<HTMLElement>('.js-resolve-conflicts-button')!
  const input = form.querySelector<HTMLElement>('.js-quick-pull-new-branch-name')!

  if (target.value === 'direct') {
    button.textContent = button.getAttribute('data-update-text')!
    button.removeAttribute('data-disable-invalid')
    button.removeAttribute('disabled')
    input.setAttribute('disabled', 'true')
  } else {
    button.textContent = button.getAttribute('data-new-branch-text')!
    button.setAttribute('data-disable-invalid', 'true')
    input.removeAttribute('disabled')
    validate(form)
  }
})
