import {onInput, onKey} from '../onfocus'
import {getCodeEditor} from '../code-editor'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import visible from '../visible'

const oldFilenameData = new WeakMap()

// Determine whether the filename field is valid.
function filenameValid(form: HTMLFormElement): boolean {
  const filenameField = form.querySelector<HTMLInputElement>('.js-blob-filename')!
  const ref = filenameField.value
  if (ref === '.' || ref === '..' || ref === '.git') {
    return false
  }
  return /\S/.test(filenameField.value)
}

// Determine whether the blob contents textarea is valid.
function blobContentsConsideredSubmittable(form: HTMLFormElement): boolean {
  const editorTextarea = form.querySelector<HTMLTextAreaElement>('.js-blob-contents')!
  if (editorTextarea.getAttribute('data-allow-unchanged') === 'true') {
    return true
  }
  return fieldConsideredChanged(editorTextarea)
}

function newFilenameConsideredChanged(form: HTMLFormElement) {
  const newFilenameField = form.querySelector<HTMLInputElement>('.js-new-filename-field')!
  return fieldConsideredChanged(newFilenameField)
}

function forkIsNotOK(form: HTMLFormElement) {
  return Array.from(form.querySelectorAll<HTMLElement>('.js-check-for-fork')).some(visible)
}

// Determine whether the blob edit form should be allowed to be submitted.
function canSubmit(form: HTMLFormElement): boolean {
  if (forkIsNotOK(form)) {
    return false
  }
  if (!filenameValid(form)) {
    return false
  }

  return blobContentsConsideredSubmittable(form) || newFilenameConsideredChanged(form)
}

// Determine whether to count this change as an 'edit' in our stats.
function trackAsBlobEdit(form: HTMLFormElement): boolean {
  const editorTextarea = form.querySelector<HTMLTextAreaElement>('.js-blob-contents')!
  if (editorTextarea.getAttribute('data-allow-unchanged')) {
    return true
  }
  return fieldConsideredChanged(editorTextarea)
}

function hasChanges(form: HTMLFormElement) {
  const editorTextarea = form.querySelector<HTMLTextAreaElement>('.js-blob-contents')!
  return fieldConsideredChanged(editorTextarea) || newFilenameConsideredChanged(form)
}

let confirmUnload: (() => string | null) | null = null

function extractConfirmUnload(form: HTMLFormElement): (() => string | null) | null {
  let message = form.getAttribute('data-github-confirm-unload')
  if (message === 'yes' || message === 'true') {
    message = ''
  }
  if (message == null) {
    message = 'false'
  }
  if (message === 'no' || message === 'false') {
    return null
  } else {
    return function () {
      return message
    }
  }
}

// Revalidate form inputs and update submit button state.
function revalidateBlobForm() {
  const form = document.querySelector<HTMLFormElement>('.js-blob-form')
  if (!form) return
  const button = form.querySelector('.js-blob-submit')
  if (button instanceof HTMLButtonElement) button.disabled = !canSubmit(form)
  form.querySelector<HTMLInputElement>('.js-blob-contents-changed')!.value = trackAsBlobEdit(form).toString()
  if (confirmUnload) {
    if (hasChanges(form)) {
      window.onbeforeunload = confirmUnload
    } else {
      window.onbeforeunload = null
    }
  }
}

// hidden input elements do not have a working .defaultValue
// .defaultValue and .defaultValue= are aliased to .value
// and .value=, so we store their initial values in an
// attribute.
//
// TBD: using a WeakMap and the idempotency of its keys
function setDefaultValues(form: HTMLFormElement) {
  for (const ref of form.querySelectorAll<HTMLInputElement>('input')) {
    if (ref.getAttribute('type') === 'hidden' && ref.getAttribute('class')) {
      if (ref.getAttribute('data-default-value') == null) {
        ref.setAttribute('data-default-value', ref.value)
      }
    }
  }
}

function fieldConsideredChanged(field: HTMLInputElement | HTMLTextAreaElement) {
  if (field == null) {
    return true
  }
  if (field.type === 'hidden') {
    return field.value !== field.getAttribute('data-default-value')
  } else {
    return field.value !== field.defaultValue
  }
}

function setOldFilename(form: HTMLFormElement) {
  const editorTextarea = form.querySelector<HTMLTextAreaElement>('.js-blob-contents')!
  const newFilenameField = form.querySelector<HTMLInputElement>('.js-new-filename-field')!
  const filenameField = form.querySelector<HTMLInputElement>('.js-blob-filename')!
  const isNewFile = filenameField.hasAttribute('data-new-file')
  if (filenameField.defaultValue != null && filenameField.defaultValue.length && !isNewFile) {
    return oldFilenameData.set(editorTextarea, newFilenameField.value)
  }
}

// Trigger validation on page load
observe('.js-blob-form', {
  constructor: HTMLFormElement,
  initialize(el) {
    setTimeout(() => {
      setDefaultValues(el)
      setOldFilename(el)
      revalidateBlobForm()
      confirmUnload = extractConfirmUnload(el)
      el.addEventListener('submit', function () {
        window.onbeforeunload = null
      })
    })
  },
})

// Trigger revalidation on code changes
on('change', '.js-blob-contents', function () {
  const field = document.querySelector<HTMLInputElement>('.js-blob-filename')
  if (!field) return
  syncFields(field)
})

// Updates commit summary placeholder when filename changes
onInput('.js-blob-filename', function (event) {
  const field = event.currentTarget as HTMLInputElement
  document.querySelector<HTMLElement>('.js-blob-contents')!.setAttribute('data-filename', field.value)
  checkForTemplateSuggestion(field.value)
  syncFields(field)
})

onInput('.js-breadcrumb-nav', function (event) {
  const field = event.currentTarget as HTMLInputElement
  watchforSegmentChanges(field)
  syncFields(field)
})

observe('.js-breadcrumb-nav', {
  constructor: HTMLInputElement,
  initialize(field) {
    watchforSegmentChanges(field)
    syncFields(field)
  },
})

onKey('keydown', '.js-breadcrumb-nav', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable no-restricted-syntax */
  const field = event.currentTarget as HTMLInputElement
  if (event.key === 'Backspace' && field.selectionStart === 0 && field.selectionEnd === 0) {
    const parent = field.parentElement
    if (parent && parent.querySelectorAll('.separator').length !== 1) {
      filenameNavUp(field, true)
      event.preventDefault()
    }
  }
  syncFields(field)
  /* eslint-enable no-restricted-syntax */
})

function syncFields(filenameField: HTMLInputElement) {
  updateNewFilenameField(filenameField)
  updateCommitPlaceholder(filenameField)
  revalidateBlobForm()
}

function watchforSegmentChanges(filenameField: HTMLInputElement) {
  function setCaret() {
    filenameField.focus()
    filenameField.setSelectionRange(0, 0)
  }

  while (filenameField.value.split('/').length > 1) {
    const filename = filenameField.value
    const segments = filename.split('/')
    const first = segments[0]!
    const theRest = segments.slice(1).join('/')
    if (first === '' || first === '.' || first === '.git') {
      filenameField.value = theRest

      window.setTimeout(setCaret, 1)
    } else if (first === '..') {
      filenameNavUp(filenameField)
    } else {
      filenameNavDown(filenameField, first, theRest)
    }
  }
}

function checkForTemplateSuggestion(filename: string): void {
  const filepath = extractFilePath()
  for (const templateSuggestion of document.querySelectorAll('.js-template-suggestion')) {
    const regexpString = templateSuggestion.getAttribute('data-template-suggestion-pattern')

    if (regexpString) {
      const regexp = new RegExp(regexpString, 'i')
      const hasTemplateMatch = regexp.test(filepath + filename)
      /* eslint-disable-next-line github/no-d-none */
      templateSuggestion.classList.toggle('d-none', !hasTemplateMatch)
      if (hasTemplateMatch) {
        const templateLink: HTMLAnchorElement | null = templateSuggestion.querySelector('a[data-template-button]')
        if (!templateLink) {
          return
        }
        const url = new URL(templateLink.href, window.location.origin)
        url.searchParams.set('filename', filename)
        templateLink.href = `${url.pathname}${url.search}`
      }
    }
  }
}

function updateCommitPlaceholder(filenameField: HTMLInputElement) {
  let noPathChange = true
  let message = filenameField.value ? `Create ${filenameField.value}` : 'Create new file'
  const form = filenameField.closest<HTMLFormElement>('form')!
  const editorTextarea = document.querySelector<HTMLTextAreaElement>('.js-blob-contents')!
  const commitSummary = form.querySelector('.js-new-blob-commit-summary')
  if (!commitSummary) return
  const commitMessageFallback = document.querySelector<HTMLInputElement>('.js-commit-message-fallback')!

  const oldFilename = oldFilenameData.get(editorTextarea)
  const newFilename = document.querySelector<HTMLInputElement>('.js-new-filename-field')!.value

  if (oldFilename) {
    if (newFilename !== oldFilename) {
      const editorHasChanges = fieldConsideredChanged(editorTextarea)
      const actions = editorHasChanges ? 'Update and rename' : 'Rename'
      if (filenameField.value.length && newFilename.length) {
        const oldSegments = oldFilename.split('/')
        const newSegments = newFilename.split('/')
        const filenameSegmentIndex = oldSegments.length - 1
        for (let i = 0; i < oldSegments.length; i++) {
          const segment = oldSegments[i]
          if (i !== filenameSegmentIndex && segment !== newSegments[i]) {
            noPathChange = false
          }
        }
        if (oldSegments.length === newSegments.length && noPathChange) {
          message = `${actions} ${oldSegments[filenameSegmentIndex]} to ${newSegments[filenameSegmentIndex]}`
        } else {
          message = `${actions} ${oldFilename} to ${newFilename}`
        }
      } else {
        message = `${actions} ${oldFilename}`
      }
    } else if (newFilename === oldFilename) {
      // eslint-disable-next-line i18n-text/no-en
      message = `Update ${filenameField.value}`
    }
  }

  commitSummary.setAttribute('placeholder', message)
  commitMessageFallback.value = message
}

function updateNewFilenameField(filenameField: HTMLInputElement) {
  const newFilename = extractFilePath() + filenameField.value
  document.querySelector<HTMLInputElement>('.js-new-filename-field')!.value = newFilename
}

function extractFilePath() {
  let newFilename = ''
  for (const segment of document.querySelectorAll('.js-breadcrumb-container > .js-path-segment')) {
    newFilename = `${newFilename}${segment.textContent}/`
  }
  return newFilename
}

function filenameNavUp(filenameField: HTMLInputElement, backspace = false) {
  if (!backspace) {
    filenameField.value = filenameField.value.replace('../', '')
  }
  let caretPoint
  const container = document.querySelector<HTMLElement>('.js-breadcrumb-container')!

  function setCaret(bksp: boolean, point = 0) {
    if (point === 0 || bksp) {
      filenameField.focus()
      filenameField.setSelectionRange(point, point)
    }
  }

  if (container.querySelectorAll('.separator').length !== 1) {
    if (filenameField.previousElementSibling) filenameField.previousElementSibling.remove() // Remove separator
    const parentDir = filenameField.previousElementSibling! // .js-path-segment
    caretPoint = parentDir.textContent!.length
    parentDir.remove()
    if (backspace) {
      filenameField.value = `${parentDir.textContent}${filenameField.value}`
    }
  }

  checkForTemplateSuggestion(filenameField.value)
  window.setTimeout(setCaret, 1, backspace, caretPoint)
}

function filenameNavDown(filenameField: HTMLInputElement, newDirectory: string, filename: string) {
  const newDir = newDirectory.trim()
  const container = document.querySelector<HTMLElement>('.js-breadcrumb-container')!
  if (newDir.length > 0) {
    const segments = container.querySelectorAll<HTMLAnchorElement>('.js-path-segment a')
    let url
    if (segments.length > 1) {
      // Set directory path based on parent directory
      url = segments[segments.length - 1]!.pathname
    } else {
      // Set directory path based on root
      const rootLink = container.querySelector<HTMLAnchorElement>('.js-repo-root a')!
      url = rootLink.pathname
    }

    const newBreadcrumb = document.querySelector<HTMLElement>('.js-crumb-template')!.cloneNode(true) as HTMLElement
    newBreadcrumb.classList.remove('js-crumb-template')
    newBreadcrumb.querySelector<HTMLAnchorElement>('a')!.href = url
    newBreadcrumb.querySelector<HTMLElement>('span')!.textContent = newDir

    const newSeparator = document.querySelector<HTMLElement>('.js-crumb-separator')!.cloneNode(true) as HTMLElement
    newSeparator.classList.remove('js-crumb-separator')
    filenameField.before(newBreadcrumb)
    filenameField.before(newSeparator)
  }
  filenameField.value = filename
  checkForTemplateSuggestion(filenameField.value)

  function setCaret() {
    filenameField.focus()
    filenameField.setSelectionRange(0, 0)
  }

  window.setTimeout(setCaret, 1)
}

// Show too long message if commit summary is greater than 50c.
//   'Great commit summaries contain fewer than 50 characters'
onInput('.js-new-blob-commit-summary', function (event) {
  const field = event.currentTarget as HTMLInputElement
  const form = field.closest<HTMLElement>('.js-file-commit-form')!
  const error = form.querySelector<HTMLElement>('.js-too-long-error')!
  /* eslint-disable-next-line github/no-d-none */
  error.classList.toggle('d-none', field.value.length <= 50)
})

// Scan page for new 'check for fork' element and start polling.
observe('.js-check-for-fork', function (el) {
  const form = el.closest<HTMLFormElement>('form')!
  const button = form.querySelector<HTMLButtonElement>('.js-blob-submit')!

  el.addEventListener('load', function () {
    // Don't set to false if button has been disabled elsewhere
    if (forkIsNotOK(form) === true) button.disabled = true
  })
})

on('click', '.js-citation-template', async function (event) {
  await fetchAndInsertIntoEditor(event)
})

// Gitignore template populator
on('change', '.js-gitignore-template input[type=radio]', async function (event) {
  await fetchAndInsertIntoEditor(event)
})

async function fetchAndInsertIntoEditor(event: Event & {currentTarget: Element}) {
  const form = event.currentTarget.closest<HTMLFormElement>('.js-blob-form')!
  const editor = getCodeEditor(form.querySelector<HTMLElement>('.js-code-editor')!)
  if (editor == null) {
    return
  }
  const url = event.currentTarget.getAttribute('data-template-url')!
  const response = await fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
  if (!response.ok) {
    return
  }
  const data = await response.text()

  editor.setCode(data)
}

// If the query param `start_commit` is true, we want to open the "Start
// commit" modal.
observe('.js-file-commit-form', function (el) {
  const params = new URLSearchParams(window.location.search.slice(1))
  if (params.get('start_commit') === 'true') {
    const startCommitButton = el.querySelector<HTMLButtonElement>('.js-details-target')!
    if (startCommitButton) {
      startCommitButton.click()
    }
  }
})
